import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import Title from '../page-title'
import SubGrey from '../page-subtitle-grey'
import Image400 from '../page-image-400'
import { fontBold, fontLight, fontThin, theme } from '../../util/style'
import IconRightArrow from '../svg/icon-right-arrow'
import pxtoVw from '../../../util/pxToVw'
import Image from '../page-image-new'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import PageDescription from '../page-description'

const HouseSeriesRowLinkItem = ({
  heading,
  number,
  textColor,
  bgColor,
  link = undefined,
  svg,
  icon = <IconRightArrow />,
  iconRotate = 0,
  label,

  ...rest
}) => {
  return (
    <Link to={link}>
      <Wrapper bgColor={bgColor} {...rest} mb={['11px']}>
        <InfoWrapper px={['16px', '30px']} py={['14px', '24px']}>
          <Heading textColor={textColor}>
            <PageDescription>{heading}</PageDescription>
          </Heading>
          <Number textColor={textColor}>{number}</Number>
        </InfoWrapper>
        <Right pt={['16px', '30px']} pr={['16px', '30px']}>
          {svg && <ImageWrapper mr={[0, '215px']}>{svg}</ImageWrapper>}
          {icon && (
            <Icon iconRotate={iconRotate} textColor={textColor}>
              {icon}
            </Icon>
          )}
          {label && (
            <Box ml={[2, 3]}>
              <PageDescription>{label}</PageDescription>
            </Box>
          )}
        </Right>
      </Wrapper>
    </Link>
  )
}
export default HouseSeriesRowLinkItem

const Wrapper = styled(Box)`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  justify-content: space-between;
  position: relative;

  @media (max-width: ${theme.breakpoints[0]}) {
    height: 215px;
  }

  @media (max-width: 480px) {
    height: 150px;
  }

  height: 190px;
  overflow: hidden;

  b {
    font-weight: normal;
    font-family: ${theme.font.fontFamilyBold};
  }
`
const Heading = styled(Box)`
  color: ${({ textColor }) => textColor};
  /* @media (max-width: ${theme.breakpoints[3]}) {
    margin-bottom: 60px;
  }
  @media (max-width: 800px) {
    margin-bottom: 74px;
  }
  margin-bottom: 90px; */

  @media (max-width: 480px) {
    * {
      font-size: 18px !important;
    }
  }
`
const Number = styled(Box)`
  ${fontThin()}
  color: ${({ textColor }) => textColor};
  font-size: 100px;
  line-height: 100%;
  @media (max-width: 800px) {
    font-size: 72px;
  }
  @media (max-width: 480px) {
    font-size: 50px;
  }
  letter-spacing: -0.05em;
`

const ImageWrapper = styled(Box)`
  /* @media (max-width: ${theme.breakpoints[3]}) {
    width: ${pxtoVw('440px')};
  } */

  @media (max-width: ${theme.breakpoints[3]}) {
    width: 370px;
  }

  @media (max-width: 800px) {
    width: 230px;
  }

  @media (max-width: ${theme.breakpoints[0]}) {
    width: ${pxtoVw('400px', 0)};
    position: absolute;
    bottom: 0;
    right: 16px;
    max-width: 320px;
  }

  @media (max-width: 480px) {
    width: ${pxtoVw('400px', 0)};
    position: absolute;
    bottom: 0;
    right: 16px;
    max-width: 212px;
  }

  right: 26px;

  width: 380px;
  height: 100%;
  position: relative;
  svg {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: 0;
    @media (max-width: 800px) {
      bottom: 0;
      top: unset;
    }
  }
`
const Right = styled(Box)`
  display: flex;
`
const Icon = styled(Box)`
  width: 45px;
  height: 45px;
  @media (max-width: 800px) {
    width: 32px;
    height: 32px;
  }
  @media (max-width: 480px) {
    width: 25px;
    height: 25px;
  }
  svg {
    width: 100%;
    height: 100%;
    display: block;
    color: ${({ textColor }) => textColor};
  }
  transform: ${({ iconRotate }) => `rotate(${iconRotate}deg)`};
`

const InfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
