import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import Title from '../page-title'
import SubGrey from '../page-subtitle-grey'
import Image400 from '../page-image-400'
import { fontBold, fontLight, theme } from '../../util/style'
import PageDescription from '../page-description'

const HouseSeriesRow = ({
  img = '/',
  heading,
  body,
  children,
  noCopy,
  ...rest
}) => {
  return (
    <Wrapper px={2} {...rest}>
      {!noCopy && (
        <PageHeading mt={['35px', '85px']} as="h1" pr={2} mb={['38px', '50px']}>
          Design ideas culminate in our Haus Series – a starting point for
          conversation and inspiration that can be shaped into your home.
        </PageHeading>
      )}
      <LinkWrapper>{children}</LinkWrapper>
    </Wrapper>
  )
}
export default HouseSeriesRow

const Wrapper = styled(Box)``
const Overview = styled(Box)`
  width: 45%;
  @media (max-width: 800px) {
    width: 90%;
  }
`

const LinkWrapper = styled(Box)``

const PageHeading = styled(Box)`
  font-weight: normal;
  font-family: ${theme.font.fontFamilySerifThin};
  font-size: 30px;
  line-height: 140%;
  width: 50%;

  @media (max-width: 800px) {
    width: 90%;
  }
`
