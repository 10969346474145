import React from 'react'

const HausSeries3 = () => {
  return (
    <svg width="440" height="228" viewBox="0 0 440 228" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_721_11611)">
    <path d="M157.84 16.52V158.52L394.84 158.52V16.52L157.84 16.52Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M37.8281 152.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M37.8281 144.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M37.8281 136.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M37.8281 128.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M158.328 120.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M158.328 112.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M158.328 104.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M158.328 96.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M158.328 88.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M158.328 80.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M158.328 72.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M158.328 64.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M158.328 56.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M158.328 48.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M158.328 40.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M158.328 32.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M158.328 24.52H394.828" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M45.8281 129.02V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M37.8281 129.02V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M61.8281 129.02V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M77.8281 129.02V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M93.8281 129.02V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M109.828 129.02V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M125.828 129.02V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M141.828 129.02V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M157.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M173.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M189.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M205.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M221.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M237.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M253.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M269.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M285.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M301.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M317.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M333.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M349.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M365.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M381.828 16.52V158.52" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M126.828 281.51V180.51H60.8281V281.51H126.828Z" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M120.84 242.51H137.84V219.51H120.84V242.51Z" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M120.84 219.51H137.84V196.51H120.84V219.51Z" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M139.828 196.52H144.828V219.52V242.52V265.52H139.828" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M144.84 196.51H120.84V192.51C120.84 191.96 121.29 191.51 121.84 191.51H143.84C144.39 191.51 144.84 191.96 144.84 192.51V196.51V196.51Z" fill="#C4D39B" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M137.828 219.52H144.828" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M91.8281 246.52C100.112 246.52 106.828 239.804 106.828 231.52C106.828 223.236 100.112 216.52 91.8281 216.52C83.5439 216.52 76.8281 223.236 76.8281 231.52C76.8281 239.804 83.5439 246.52 91.8281 246.52Z" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M43.8398 257.51H52.8398L52.8398 204.51H43.8398L43.8398 257.51Z" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M46.8398 250.51H48.8398L48.8398 211.51H46.8398L46.8398 250.51Z" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M79.6014 175.311C84.2912 175.344 88.0745 179.18 88.0418 183.87L88.0121 188.12C87.9958 190.46 86.0825 192.357 83.7326 192.34L75.2328 192.281C72.8928 192.265 70.9961 190.351 71.0126 188.001L71.0422 183.751C71.075 179.061 74.9115 175.278 79.6014 175.311Z" fill="#C4D39B" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M79.589 175.32C83.839 175.35 87.329 178.49 87.939 182.56C88.049 183.31 88.659 183.88 89.419 183.89C90.349 183.89 91.049 183.07 90.909 182.15C90.099 176.63 85.359 172.36 79.609 172.32C73.859 172.28 69.059 176.48 68.169 181.99C68.019 182.91 68.709 183.75 69.639 183.75C70.399 183.75 71.009 183.19 71.139 182.44C71.799 178.38 75.339 175.29 79.589 175.32" fill="#C4D39B"/>
    <path d="M79.589 175.32C83.839 175.35 87.329 178.49 87.939 182.56C88.049 183.31 88.659 183.88 89.419 183.89C90.349 183.89 91.049 183.07 90.909 182.15C90.099 176.63 85.359 172.36 79.609 172.32C73.859 172.28 69.059 176.48 68.169 181.99C68.019 182.91 68.709 183.75 69.639 183.75C70.399 183.75 71.009 183.19 71.139 182.44C71.799 178.38 75.339 175.29 79.589 175.32" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M105.601 175.492C110.291 175.525 114.075 179.362 114.042 184.052L114.012 188.301C113.996 190.641 112.083 192.538 109.733 192.522L101.233 192.462C98.8928 192.446 96.9961 190.533 97.0126 188.183L97.0422 183.933C97.075 179.243 100.911 175.46 105.601 175.492Z" fill="#C4D39B" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M105.589 175.5C109.839 175.53 113.329 178.67 113.939 182.74C114.049 183.49 114.659 184.06 115.419 184.07C116.349 184.07 117.049 183.25 116.909 182.33C116.099 176.81 111.359 172.54 105.609 172.5C99.859 172.46 95.059 176.66 94.169 182.17C94.019 183.09 94.709 183.93 95.639 183.93C96.399 183.93 97.009 183.37 97.139 182.62C97.799 178.56 101.339 175.47 105.589 175.5" fill="#C4D39B"/>
    <path d="M105.589 175.5C109.839 175.53 113.329 178.67 113.939 182.74C114.049 183.49 114.659 184.06 115.419 184.07C116.349 184.07 117.049 183.25 116.909 182.33C116.099 176.81 111.359 172.54 105.609 172.5C99.859 172.46 95.059 176.66 94.169 182.17C94.019 183.09 94.709 183.93 95.639 183.93C96.399 183.93 97.009 183.37 97.139 182.62C97.799 178.56 101.339 175.47 105.589 175.5" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M233.828 191.23V199.81C233.828 200.76 233.058 201.52 232.118 201.52H229.828C226.518 201.52 223.828 198.83 223.828 195.52C223.828 192.21 226.518 189.52 229.828 189.52H232.118C233.068 189.52 233.828 190.29 233.828 191.23V191.23Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M222.828 195.52C222.828 192.7 224.498 190.28 226.898 189.17C227.568 188.86 228.328 189.36 228.328 190.09C228.328 190.47 228.118 190.83 227.768 190.99C226.038 191.77 224.828 193.5 224.828 195.52C224.828 197.54 226.038 199.26 227.768 200.05C228.118 200.21 228.328 200.57 228.328 200.95C228.328 201.69 227.558 202.18 226.898 201.87C224.498 200.76 222.828 198.33 222.828 195.52Z" fill="#C4D39B" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M233.828 207.23V215.81C233.828 216.76 233.058 217.52 232.118 217.52H229.828C226.518 217.52 223.828 214.83 223.828 211.52C223.828 208.21 226.518 205.52 229.828 205.52H232.118C233.068 205.52 233.828 206.29 233.828 207.23V207.23Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M222.828 211.52C222.828 208.7 224.498 206.28 226.898 205.17C227.568 204.86 228.328 205.36 228.328 206.09C228.328 206.47 228.118 206.83 227.768 206.99C226.038 207.77 224.828 209.5 224.828 211.52C224.828 213.54 226.038 215.26 227.768 216.05C228.118 216.21 228.328 216.57 228.328 216.95C228.328 217.69 227.558 218.18 226.898 217.87C224.498 216.76 222.828 214.33 222.828 211.52Z" fill="#C4D39B" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M233.828 223.23V231.81C233.828 232.76 233.058 233.52 232.118 233.52H229.828C226.518 233.52 223.828 230.83 223.828 227.52C223.828 224.21 226.518 221.52 229.828 221.52H232.118C233.068 221.52 233.828 222.29 233.828 223.23V223.23Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M222.828 227.52C222.828 224.7 224.498 222.28 226.898 221.17C227.568 220.86 228.328 221.36 228.328 222.09C228.328 222.47 228.118 222.83 227.768 222.99C226.038 223.77 224.828 225.5 224.828 227.52C224.828 229.54 226.038 231.26 227.768 232.05C228.118 232.21 228.328 232.57 228.328 232.95C228.328 233.69 227.558 234.18 226.898 233.87C224.498 232.76 222.828 230.33 222.828 227.52Z" fill="#C4D39B" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M230.836 184.52V289.52H256.836V184.52H230.836Z" fill="#C4D39B" stroke="#7FA25A" stroke-width="0.5" stroke-linejoin="round"/>
    <path d="M348.84 161.52V300.52H364.84V161.52H348.84Z" stroke="#3E6F42" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M74.8281 161.51V158.51H48.8281V161.51H74.8281Z" fill="#C4D39B" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M100.828 161.51V158.51H74.8281V161.51H100.828Z" fill="#C4D39B" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M126.828 161.51V158.51H100.828V161.51H126.828Z" fill="#C4D39B" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M152.828 161.51V158.51H126.828V161.51H152.828Z" fill="#C4D39B" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M204.828 161.51V158.51H178.828V161.51H204.828Z" fill="#C4D39B" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M230.828 161.51V158.51H204.828V161.51H230.828Z" fill="#C4D39B" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M256.828 161.51V158.51H230.828V161.51H256.828Z" fill="#C4D39B" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M282.828 161.51V158.51H256.828V161.51H282.828Z" fill="#C4D39B" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M315.828 161.52H313.828V184.52H315.828V161.52Z" fill="#C4D39B" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M316.688 184.21C316.688 184.21 316.767 184.21 316.807 184.21" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M317.82 184.13C328.49 183.13 336.83 174.15 336.83 163.22V163.14" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M336.828 162.64V162.52" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M287.84 161.52V269.52H307.84V161.52H287.84Z" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M300.828 224.52C301.38 224.52 301.828 224.072 301.828 223.52C301.828 222.968 301.38 222.52 300.828 222.52C300.276 222.52 299.828 222.968 299.828 223.52C299.828 224.072 300.276 224.52 300.828 224.52Z" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M292.828 225.52C293.933 225.52 294.828 224.625 294.828 223.52C294.828 222.415 293.933 221.52 292.828 221.52C291.724 221.52 290.828 222.415 290.828 223.52C290.828 224.625 291.724 225.52 292.828 225.52Z" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M292.828 224.52C293.38 224.52 293.828 224.072 293.828 223.52C293.828 222.968 293.38 222.52 292.828 222.52C292.276 222.52 291.828 222.968 291.828 223.52C291.828 224.072 292.276 224.52 292.828 224.52Z" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M301.828 223.52H304.828" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M288.828 223.52H291.828" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M294.828 223.52H299.828" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M300.828 219.52V222.52" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M292.828 219.52V222.52" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M292.828 224.52V230.52" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M300.828 224.52V230.52" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M296.828 231.52V219.52" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M288.84 219.8V227.24C288.84 227.395 288.965 227.52 289.12 227.52H304.56C304.714 227.52 304.84 227.395 304.84 227.24V219.8C304.84 219.645 304.714 219.52 304.56 219.52H289.12C288.965 219.52 288.84 219.645 288.84 219.8Z" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M287.84 218.52V245.52H305.84V218.52H287.84Z" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M286.84 225.52V226.52H287.84V225.52H286.84Z" stroke="#7FA25A" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M178.828 158.52H152.828V161.52H178.828V158.52Z" fill="#C4D39B" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M40.8081 158.52H37.8281V161.47V300.56V303.52H40.8081H48.8281V300.52H40.8281V161.52H48.8281V158.52H40.8081Z" fill="#C4D39B" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M373.828 19.52H394.828V158.52H367.568H364.628H336.828V161.52H364.828V251.52H367.828V161.52H394.918H397.828V160.6V19.52V16.52H394.898H373.828V19.52Z" fill="#C4D39B" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M313.828 161.52V158.52H311.168H310.828H308.198H282.828V161.52H307.828V269.52H290.748H287.828V272.44V274.52H290.828V272.52H307.848H310.828V269.49V161.52H313.828Z" fill="#C4D39B" stroke="#3E6F42" stroke-width="0.5" stroke-miterlimit="10"/>
    <path d="M405.305 194.16C405.857 194.16 406.305 193.712 406.305 193.16C406.305 192.608 405.857 192.16 405.305 192.16C404.752 192.16 404.305 192.608 404.305 193.16C404.305 193.712 404.752 194.16 405.305 194.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M405.305 192.16C405.857 192.16 406.305 191.712 406.305 191.16C406.305 190.608 405.857 190.16 405.305 190.16C404.752 190.16 404.305 190.608 404.305 191.16C404.305 191.712 404.752 192.16 405.305 192.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M407.305 193.16C407.857 193.16 408.305 192.712 408.305 192.16C408.305 191.608 407.857 191.16 407.305 191.16C406.752 191.16 406.305 191.608 406.305 192.16C406.305 192.712 406.752 193.16 407.305 193.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M407.305 189.16C407.857 189.16 408.305 188.712 408.305 188.16C408.305 187.608 407.857 187.16 407.305 187.16C406.752 187.16 406.305 187.608 406.305 188.16C406.305 188.712 406.752 189.16 407.305 189.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M427.305 191.16C427.857 191.16 428.305 190.712 428.305 190.16C428.305 189.608 427.857 189.16 427.305 189.16C426.752 189.16 426.305 189.608 426.305 190.16C426.305 190.712 426.752 191.16 427.305 191.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M392.266 184.48C394.636 187.72 402.156 191.53 404.536 191.85C404.536 191.85 402.096 189.05 398.646 186.94C395.196 184.83 392.266 184.49 392.266 184.49V184.48Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M395.233 192.45C397.203 194.17 402.183 193.97 403.583 193.13C403.583 193.13 401.663 191.83 399.303 191.6C396.943 191.37 395.223 192.45 395.223 192.45H395.233Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M406.961 184.08C407.241 186.68 411.041 189.91 412.641 190.25C412.641 190.25 412.111 187.99 410.531 186.23C408.951 184.47 406.961 184.09 406.961 184.09V184.08Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M403.171 180.23C402.621 182.73 404.641 187.45 405.801 188.51C405.801 188.51 406.061 186.2 405.341 183.85C404.621 181.5 403.171 180.23 403.171 180.23Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M415.764 178.17C414.354 180.97 415.284 187.21 416.324 188.82C416.324 188.82 417.334 186.16 417.204 183.15C417.074 180.14 415.764 178.18 415.764 178.18V178.17Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M394.625 179.47C396.165 183.18 402.555 188.67 404.795 189.54C404.795 189.54 403.095 186.24 400.245 183.37C397.395 180.5 394.635 179.47 394.635 179.47H394.625Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M422.305 190.16C422.857 190.16 423.305 189.712 423.305 189.16C423.305 188.608 422.857 188.16 422.305 188.16C421.752 188.16 421.305 188.608 421.305 189.16C421.305 189.712 421.752 190.16 422.305 190.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M425.305 189.16C425.857 189.16 426.305 188.712 426.305 188.16C426.305 187.608 425.857 187.16 425.305 187.16C424.752 187.16 424.305 187.608 424.305 188.16C424.305 188.712 424.752 189.16 425.305 189.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M432.773 175.31C429.243 177.24 424.493 184.19 423.863 186.51C423.863 186.51 426.963 184.46 429.503 181.31C432.043 178.16 432.773 175.31 432.773 175.31V175.31Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M425.811 175.64C423.371 178 421.381 184.74 421.531 186.77C421.531 186.77 423.571 184.41 424.801 181.27C426.031 178.13 425.801 175.64 425.801 175.64H425.811Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M435.323 187.15C433.103 186.03 428.223 186.89 426.973 187.77C426.973 187.77 429.083 188.56 431.443 188.41C433.803 188.26 435.323 187.14 435.323 187.14V187.15Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M435.045 180.23C431.805 180.66 426.805 185.02 425.945 186.82C425.945 186.82 428.845 186.14 431.435 184.3C434.025 182.46 435.045 180.23 435.045 180.23Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M423.305 195.16C423.857 195.16 424.305 194.712 424.305 194.16C424.305 193.608 423.857 193.16 423.305 193.16C422.752 193.16 422.305 193.608 422.305 194.16C422.305 194.712 422.752 195.16 423.305 195.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M425.305 196.16C425.857 196.16 426.305 195.712 426.305 195.16C426.305 194.608 425.857 194.16 425.305 194.16C424.752 194.16 424.305 194.608 424.305 195.16C424.305 195.712 424.752 196.16 425.305 196.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M438.96 188.88C434.95 188.67 427.25 192.1 425.5 193.74C425.5 193.74 429.21 193.62 433.03 192.28C436.85 190.94 438.96 188.88 438.96 188.88Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M434.924 200.3C433.624 198.18 429.014 196.35 427.484 196.45C427.484 196.45 428.864 198.23 430.954 199.34C433.044 200.45 434.924 200.3 434.924 200.3V200.3Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M439.222 194.24C435.992 192.91 428.912 194.01 427.102 195.1C427.102 195.1 430.162 196.03 433.582 195.82C437.002 195.61 439.212 194.24 439.212 194.24H439.222Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M416.305 203.16C416.857 203.16 417.305 202.712 417.305 202.16C417.305 201.608 416.857 201.16 416.305 201.16C415.752 201.16 415.305 201.608 415.305 202.16C415.305 202.712 415.752 203.16 416.305 203.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M411.902 217.29C413.292 215.22 413.032 210.27 412.312 208.92C412.312 208.92 411.272 210.91 411.122 213.28C410.972 215.65 411.902 217.29 411.902 217.29Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M419.394 217.63C420.364 213.73 418.464 205.52 417.184 203.49C417.184 203.49 416.594 207.16 417.184 211.16C417.774 215.16 419.394 217.63 419.394 217.63Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M394.5 200.51C397.84 198.28 406.21 197.28 408.55 197.8C408.55 197.8 405.3 199.6 401.33 200.4C397.36 201.2 394.5 200.52 394.5 200.52V200.51Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M390.633 195.98C394.323 194.39 402.733 194.91 404.943 195.84C404.943 195.84 401.423 197.02 397.373 197.09C393.323 197.16 390.633 195.98 390.633 195.98Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M394.721 206.59C397.311 203.51 405.061 200.22 407.451 200.06C407.451 200.06 404.831 202.69 401.241 204.57C397.651 206.45 394.711 206.59 394.711 206.59H394.721Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M413.305 204.16C413.857 204.16 414.305 203.712 414.305 203.16C414.305 202.608 413.857 202.16 413.305 202.16C412.752 202.16 412.305 202.608 412.305 203.16C412.305 203.712 412.752 204.16 413.305 204.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M410.305 203.16C410.857 203.16 411.305 202.712 411.305 202.16C411.305 201.608 410.857 201.16 410.305 201.16C409.752 201.16 409.305 201.608 409.305 202.16C409.305 202.712 409.752 203.16 410.305 203.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M407.156 218.24C410.136 215.38 412.206 207.19 411.856 204.72C411.856 204.72 409.396 207.59 408.036 211.4C406.666 215.21 407.156 218.25 407.156 218.25V218.24Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M415.452 214.58C416.862 211.82 415.932 205.67 414.892 204.08C414.892 204.08 413.882 206.71 414.012 209.68C414.142 212.65 415.452 214.59 415.452 214.59V214.58Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M401.402 206.56C403.872 206.9 408.202 204.5 409.102 203.27C409.102 203.27 406.852 203.2 404.662 204.11C402.472 205.02 401.402 206.57 401.402 206.57V206.56Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M401.582 215.95C405.012 213.86 409.442 206.69 409.952 204.35C409.952 204.35 406.952 206.54 404.562 209.81C402.162 213.07 401.582 215.96 401.582 215.96V215.95Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M423.305 200.16C423.857 200.16 424.305 199.712 424.305 199.16C424.305 198.608 423.857 198.16 423.305 198.16C422.752 198.16 422.305 198.608 422.305 199.16C422.305 199.712 422.752 200.16 423.305 200.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M422.305 202.16C422.857 202.16 423.305 201.712 423.305 201.16C423.305 200.608 422.857 200.16 422.305 200.16C421.752 200.16 421.305 200.608 421.305 201.16C421.305 201.712 421.752 202.16 422.305 202.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M421.305 200.16C421.857 200.16 422.305 199.712 422.305 199.16C422.305 198.608 421.857 198.16 421.305 198.16C420.752 198.16 420.305 198.608 420.305 199.16C420.305 199.712 420.752 200.16 421.305 200.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M432.774 208.39C431.594 205.33 426.184 201.48 424.234 201.06C424.234 201.06 425.584 203.73 427.974 205.82C430.364 207.92 432.774 208.39 432.774 208.39V208.39Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M436.531 204.22C434.361 201.56 427.711 199.28 425.641 199.4C425.641 199.4 427.851 201.63 430.921 203.02C433.991 204.41 436.531 204.22 436.531 204.22Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M420.515 209.07C421.195 207 419.745 202.93 418.795 201.98C418.795 201.98 418.385 203.9 418.855 205.91C419.315 207.92 420.515 209.06 420.515 209.06V209.07Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M424.19 214.78C424.98 211.62 422.76 205.41 421.41 203.97C421.41 203.97 420.98 206.9 421.74 209.97C422.49 213.03 424.19 214.78 424.19 214.78Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M408.57 201.55C410.87 200.81 414.07 197.05 414.52 195.66C414.52 195.66 412.49 196.54 410.8 198.19C409.1 199.84 408.57 201.56 408.57 201.56V201.55Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M414.495 202.17C415.825 200.65 415.615 196.83 414.945 195.76C414.945 195.76 413.945 197.24 413.785 199.05C413.625 200.86 414.485 202.17 414.485 202.17H414.495Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M420.093 201.73C420.203 199.26 417.013 195.91 415.523 195.47C415.523 195.47 415.693 197.64 416.963 199.43C418.233 201.22 420.103 201.73 420.103 201.73H420.093Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M424.145 197.07C422.385 195.35 417.505 194.53 416.055 194.9C416.055 194.9 417.805 196.28 420.085 196.92C422.365 197.56 424.145 197.07 424.145 197.07V197.07Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M425.305 190.17C422.285 189.87 417.095 192.98 416.055 194.52C416.055 194.52 418.795 194.49 421.425 193.3C424.055 192.11 425.305 190.18 425.305 190.18V190.17Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M420.756 184.51C418.326 186.1 416.086 191.53 416.126 193.3C416.126 193.3 418.176 191.63 419.516 189.16C420.856 186.69 420.766 184.51 420.766 184.51H420.756Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M413.123 185.63C412.203 188.08 414.133 192.67 415.413 193.69C415.413 193.69 415.963 191.44 415.343 189.15C414.723 186.86 413.123 185.63 413.123 185.63Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M407.734 189.59C408.604 192.05 413.034 194.33 414.674 194.29C414.674 194.29 413.644 192.21 411.704 190.85C409.764 189.49 407.734 189.58 407.734 189.58V189.59Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M406.055 195.77C408.295 196.89 413.165 195.92 414.395 195C414.395 195 412.275 194.22 409.915 194.41C407.555 194.6 406.055 195.77 406.055 195.77V195.77Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M429.901 213.74C429.521 210.16 425.121 204.18 423.281 203.02C423.281 203.02 423.931 206.26 425.771 209.31C427.611 212.36 429.891 213.74 429.891 213.74H429.901Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M410.305 184.16C410.857 184.16 411.305 183.712 411.305 183.16C411.305 182.608 410.857 182.16 410.305 182.16C409.752 182.16 409.305 182.608 409.305 183.16C409.305 183.712 409.752 184.16 410.305 184.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M412.305 183.16C412.857 183.16 413.305 182.712 413.305 182.16C413.305 181.608 412.857 181.16 412.305 181.16C411.752 181.16 411.305 181.608 411.305 182.16C411.305 182.712 411.752 183.16 412.305 183.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M412.305 185.16C412.857 185.16 413.305 184.712 413.305 184.16C413.305 183.608 412.857 183.16 412.305 183.16C411.752 183.16 411.305 183.608 411.305 184.16C411.305 184.712 411.752 185.16 412.305 185.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M410.742 170.07C409.172 173.04 409.742 179.87 410.682 181.68C410.682 181.68 411.842 178.84 411.892 175.56C411.942 172.28 410.742 170.07 410.742 170.07Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M406.164 173.54C405.534 176.07 407.974 180.42 409.354 181.29C409.354 181.29 409.644 178.99 408.774 176.78C407.904 174.57 406.164 173.53 406.164 173.53V173.54Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M401.613 175.3C402.313 178.13 406.563 182.34 408.183 183.02C408.183 183.02 407.293 180.5 405.463 178.3C403.633 176.1 401.613 175.3 401.613 175.3V175.3Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M416.233 169.51C413.893 171.93 412.533 178.66 412.933 180.66C412.933 180.66 414.843 178.25 415.813 175.11C416.783 171.97 416.243 169.51 416.243 169.51H416.233Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M417.995 171.13C416.725 174.67 417.975 182.37 419.095 184.33C419.095 184.33 419.965 180.98 419.685 177.25C419.405 173.51 417.995 171.13 417.995 171.13V171.13Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M422.912 171.12C420.852 173.59 420.212 180.05 420.822 181.91C420.822 181.91 422.462 179.49 423.082 176.44C423.702 173.39 422.912 171.12 422.912 171.12Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M15.3047 49.16C15.857 49.16 16.3047 48.7123 16.3047 48.16C16.3047 47.6077 15.857 47.16 15.3047 47.16C14.7524 47.16 14.3047 47.6077 14.3047 48.16C14.3047 48.7123 14.7524 49.16 15.3047 49.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M15.3047 47.16C15.857 47.16 16.3047 46.7123 16.3047 46.16C16.3047 45.6077 15.857 45.16 15.3047 45.16C14.7524 45.16 14.3047 45.6077 14.3047 46.16C14.3047 46.7123 14.7524 47.16 15.3047 47.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M17.3047 48.16C17.857 48.16 18.3047 47.7123 18.3047 47.16C18.3047 46.6077 17.857 46.16 17.3047 46.16C16.7524 46.16 16.3047 46.6077 16.3047 47.16C16.3047 47.7123 16.7524 48.16 17.3047 48.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M17.3047 44.16C17.857 44.16 18.3047 43.7123 18.3047 43.16C18.3047 42.6077 17.857 42.16 17.3047 42.16C16.7524 42.16 16.3047 42.6077 16.3047 43.16C16.3047 43.7123 16.7524 44.16 17.3047 44.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M37.3047 46.16C37.857 46.16 38.3047 45.7123 38.3047 45.16C38.3047 44.6077 37.857 44.16 37.3047 44.16C36.7524 44.16 36.3047 44.6077 36.3047 45.16C36.3047 45.7123 36.7524 46.16 37.3047 46.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M2.26562 39.48C4.63562 42.72 12.1556 46.53 14.5356 46.85C14.5356 46.85 12.0956 44.05 8.64563 41.94C5.19563 39.83 2.26562 39.49 2.26562 39.49V39.48Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M5.23266 47.45C7.20266 49.17 12.1827 48.97 13.5827 48.13C13.5827 48.13 11.6627 46.83 9.30266 46.6C6.94266 46.37 5.22266 47.45 5.22266 47.45H5.23266Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M16.9609 39.08C17.2409 41.68 21.0409 44.91 22.6409 45.25C22.6409 45.25 22.1109 42.99 20.5309 41.23C18.9509 39.47 16.9609 39.09 16.9609 39.09V39.08Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M13.1711 35.23C12.6211 37.73 14.6411 42.45 15.8011 43.51C15.8011 43.51 16.0611 41.2 15.3411 38.85C14.6211 36.5 13.1711 35.23 13.1711 35.23Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M25.764 33.17C24.354 35.97 25.284 42.21 26.324 43.82C26.324 43.82 27.334 41.16 27.204 38.15C27.074 35.14 25.764 33.18 25.764 33.18V33.17Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M4.62109 34.47C6.16109 38.18 12.5511 43.67 14.7911 44.54C14.7911 44.54 13.0911 41.24 10.2411 38.37C7.39109 35.5 4.63109 34.47 4.63109 34.47H4.62109Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M32.3047 45.16C32.857 45.16 33.3047 44.7123 33.3047 44.16C33.3047 43.6077 32.857 43.16 32.3047 43.16C31.7524 43.16 31.3047 43.6077 31.3047 44.16C31.3047 44.7123 31.7524 45.16 32.3047 45.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M35.3047 44.16C35.857 44.16 36.3047 43.7123 36.3047 43.16C36.3047 42.6077 35.857 42.16 35.3047 42.16C34.7524 42.16 34.3047 42.6077 34.3047 43.16C34.3047 43.7123 34.7524 44.16 35.3047 44.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M42.7733 30.3101C39.2433 32.2401 34.4933 39.1901 33.8633 41.5101C33.8633 41.5101 36.9633 39.4601 39.5033 36.3101C42.0433 33.1601 42.7733 30.3101 42.7733 30.3101V30.3101Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M35.8114 30.64C33.3714 33 31.3814 39.74 31.5314 41.77C31.5314 41.77 33.5714 39.41 34.8014 36.27C36.0314 33.13 35.8014 30.64 35.8014 30.64H35.8114Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M45.3227 42.15C43.1027 41.03 38.2227 41.89 36.9727 42.77C36.9727 42.77 39.0827 43.56 41.4427 43.41C43.8027 43.26 45.3227 42.14 45.3227 42.14V42.15Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M45.0453 35.23C41.8053 35.66 36.8053 40.02 35.9453 41.82C35.9453 41.82 38.8453 41.14 41.4353 39.3C44.0253 37.46 45.0453 35.23 45.0453 35.23V35.23Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M33.3047 50.16C33.857 50.16 34.3047 49.7123 34.3047 49.16C34.3047 48.6077 33.857 48.16 33.3047 48.16C32.7524 48.16 32.3047 48.6077 32.3047 49.16C32.3047 49.7123 32.7524 50.16 33.3047 50.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M35.3047 51.16C35.857 51.16 36.3047 50.7123 36.3047 50.16C36.3047 49.6077 35.857 49.16 35.3047 49.16C34.7524 49.16 34.3047 49.6077 34.3047 50.16C34.3047 50.7123 34.7524 51.16 35.3047 51.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M48.96 43.8801C44.95 43.6701 37.25 47.1001 35.5 48.7401C35.5 48.7401 39.21 48.6201 43.03 47.2801C46.85 45.9401 48.96 43.8801 48.96 43.8801Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M44.9244 55.3C43.6244 53.18 39.0144 51.35 37.4844 51.45C37.4844 51.45 38.8644 53.23 40.9544 54.34C43.0444 55.45 44.9244 55.3 44.9244 55.3Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M49.2216 49.2401C45.9916 47.9101 38.9116 49.01 37.1016 50.1C37.1016 50.1 40.1616 51.0301 43.5816 50.8201C47.0016 50.6101 49.2116 49.2401 49.2116 49.2401H49.2216Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M26.3047 58.16C26.857 58.16 27.3047 57.7123 27.3047 57.16C27.3047 56.6077 26.857 56.16 26.3047 56.16C25.7524 56.16 25.3047 56.6077 25.3047 57.16C25.3047 57.7123 25.7524 58.16 26.3047 58.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M21.9018 72.29C23.2918 70.22 23.0318 65.27 22.3118 63.92C22.3118 63.92 21.2718 65.91 21.1218 68.28C20.9718 70.65 21.9018 72.29 21.9018 72.29V72.29Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M29.3941 72.63C30.3641 68.73 28.4641 60.52 27.1841 58.49C27.1841 58.49 26.5941 62.16 27.1841 66.16C27.7741 70.16 29.3941 72.63 29.3941 72.63Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M4.5 55.5099C7.84 53.2799 16.21 52.2799 18.55 52.7999C18.55 52.7999 15.3 54.6 11.33 55.4C7.36 56.2 4.5 55.5199 4.5 55.5199V55.5099Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M0.632812 50.98C4.32281 49.39 12.7328 49.91 14.9428 50.84C14.9428 50.84 11.4228 52.02 7.37281 52.09C3.32281 52.16 0.632812 50.98 0.632812 50.98V50.98Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M4.72094 61.5901C7.31094 58.5101 15.0609 55.2201 17.4509 55.0601C17.4509 55.0601 14.8309 57.6901 11.2409 59.5701C7.65094 61.4501 4.71094 61.5901 4.71094 61.5901H4.72094Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M23.3047 59.16C23.857 59.16 24.3047 58.7123 24.3047 58.16C24.3047 57.6077 23.857 57.16 23.3047 57.16C22.7524 57.16 22.3047 57.6077 22.3047 58.16C22.3047 58.7123 22.7524 59.16 23.3047 59.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M20.3047 58.16C20.857 58.16 21.3047 57.7123 21.3047 57.16C21.3047 56.6077 20.857 56.16 20.3047 56.16C19.7524 56.16 19.3047 56.6077 19.3047 57.16C19.3047 57.7123 19.7524 58.16 20.3047 58.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M17.1555 73.24C20.1355 70.38 22.2055 62.19 21.8555 59.72C21.8555 59.72 19.3955 62.59 18.0355 66.4C16.6655 70.21 17.1555 73.25 17.1555 73.25V73.24Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M25.4516 69.5801C26.8616 66.8201 25.9316 60.6701 24.8916 59.0801C24.8916 59.0801 23.8816 61.7101 24.0116 64.6801C24.1416 67.6501 25.4516 69.5901 25.4516 69.5901V69.5801Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M11.4023 61.56C13.8723 61.9 18.2023 59.5 19.1023 58.27C19.1023 58.27 16.8523 58.2 14.6623 59.11C12.4723 60.02 11.4023 61.57 11.4023 61.57V61.56Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M11.582 70.95C15.012 68.86 19.442 61.69 19.952 59.35C19.952 59.35 16.952 61.54 14.562 64.81C12.162 68.07 11.582 70.96 11.582 70.96V70.95Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M33.3047 55.16C33.857 55.16 34.3047 54.7123 34.3047 54.16C34.3047 53.6077 33.857 53.16 33.3047 53.16C32.7524 53.16 32.3047 53.6077 32.3047 54.16C32.3047 54.7123 32.7524 55.16 33.3047 55.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M32.3047 57.16C32.857 57.16 33.3047 56.7123 33.3047 56.16C33.3047 55.6077 32.857 55.16 32.3047 55.16C31.7524 55.16 31.3047 55.6077 31.3047 56.16C31.3047 56.7123 31.7524 57.16 32.3047 57.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M31.3047 55.16C31.857 55.16 32.3047 54.7123 32.3047 54.16C32.3047 53.6077 31.857 53.16 31.3047 53.16C30.7524 53.16 30.3047 53.6077 30.3047 54.16C30.3047 54.7123 30.7524 55.16 31.3047 55.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M42.7744 63.3901C41.5944 60.3301 36.1844 56.4801 34.2344 56.0601C34.2344 56.0601 35.5844 58.7301 37.9744 60.8201C40.3644 62.9201 42.7744 63.3901 42.7744 63.3901Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M46.5345 59.22C44.3645 56.56 37.7145 54.28 35.6445 54.4C35.6445 54.4 37.8545 56.63 40.9245 58.02C43.9945 59.41 46.5345 59.22 46.5345 59.22Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M30.5146 64.07C31.1946 62 29.7446 57.93 28.7946 56.98C28.7946 56.98 28.3846 58.9 28.8546 60.91C29.3146 62.92 30.5146 64.06 30.5146 64.06V64.07Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M34.1902 69.78C34.9802 66.62 32.7602 60.41 31.4102 58.97C31.4102 58.97 30.9802 61.9 31.7402 64.97C32.4902 68.03 34.1902 69.78 34.1902 69.78Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M18.5703 56.55C20.8703 55.81 24.0703 52.05 24.5203 50.66C24.5203 50.66 22.4903 51.54 20.8003 53.19C19.1003 54.84 18.5703 56.56 18.5703 56.56V56.55Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M24.4955 57.17C25.8255 55.65 25.6155 51.83 24.9455 50.76C24.9455 50.76 23.9455 52.24 23.7855 54.05C23.6255 55.86 24.4855 57.17 24.4855 57.17H24.4955Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M30.0934 56.73C30.2034 54.26 27.0134 50.91 25.5234 50.47C25.5234 50.47 25.6934 52.64 26.9634 54.43C28.2334 56.22 30.1034 56.73 30.1034 56.73H30.0934Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M34.1447 52.07C32.3847 50.35 27.5047 49.53 26.0547 49.9C26.0547 49.9 27.8047 51.28 30.0847 51.92C32.3647 52.56 34.1447 52.07 34.1447 52.07Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M35.3047 45.17C32.2847 44.87 27.0947 47.98 26.0547 49.52C26.0547 49.52 28.7947 49.49 31.4247 48.3C34.0547 47.11 35.3047 45.18 35.3047 45.18V45.17Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M30.7555 39.51C28.3255 41.1 26.0855 46.53 26.1255 48.3C26.1255 48.3 28.1755 46.63 29.5155 44.16C30.8555 41.69 30.7655 39.51 30.7655 39.51H30.7555Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M23.123 40.63C22.203 43.08 24.133 47.67 25.413 48.69C25.413 48.69 25.963 46.44 25.343 44.15C24.723 41.86 23.123 40.63 23.123 40.63Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M17.7344 44.59C18.6044 47.05 23.0344 49.33 24.6744 49.29C24.6744 49.29 23.6444 47.21 21.7044 45.85C19.7644 44.49 17.7344 44.58 17.7344 44.58V44.59Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M16.0547 50.77C18.2947 51.89 23.1647 50.92 24.3947 50C24.3947 50 22.2747 49.2199 19.9147 49.4099C17.5547 49.5999 16.0547 50.77 16.0547 50.77V50.77Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M39.9013 68.74C39.5213 65.16 35.1213 59.18 33.2812 58.02C33.2812 58.02 33.9313 61.26 35.7713 64.31C37.6113 67.36 39.8913 68.74 39.8913 68.74H39.9013Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M20.3047 39.16C20.857 39.16 21.3047 38.7123 21.3047 38.16C21.3047 37.6077 20.857 37.16 20.3047 37.16C19.7524 37.16 19.3047 37.6077 19.3047 38.16C19.3047 38.7123 19.7524 39.16 20.3047 39.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M22.3047 38.16C22.857 38.16 23.3047 37.7123 23.3047 37.16C23.3047 36.6077 22.857 36.16 22.3047 36.16C21.7524 36.16 21.3047 36.6077 21.3047 37.16C21.3047 37.7123 21.7524 38.16 22.3047 38.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M22.3047 40.16C22.857 40.16 23.3047 39.7123 23.3047 39.16C23.3047 38.6077 22.857 38.16 22.3047 38.16C21.7524 38.16 21.3047 38.6077 21.3047 39.16C21.3047 39.7123 21.7524 40.16 22.3047 40.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M20.7425 25.0701C19.1725 28.0401 19.7425 34.8701 20.6825 36.6801C20.6825 36.6801 21.8425 33.8401 21.8925 30.5601C21.9425 27.2801 20.7425 25.0701 20.7425 25.0701V25.0701Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M16.1645 28.54C15.5345 31.07 17.9745 35.42 19.3545 36.29C19.3545 36.29 19.6445 33.99 18.7745 31.78C17.9045 29.57 16.1645 28.53 16.1645 28.53V28.54Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M11.6133 30.3C12.3133 33.13 16.5633 37.3401 18.1833 38.0201C18.1833 38.0201 17.2933 35.5 15.4633 33.3C13.6333 31.1 11.6133 30.3 11.6133 30.3V30.3Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M26.2327 24.51C23.8927 26.93 22.5327 33.66 22.9327 35.66C22.9327 35.66 24.8427 33.25 25.8127 30.11C26.7827 26.97 26.2427 24.51 26.2427 24.51H26.2327Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M27.9951 26.13C26.7251 29.67 27.9751 37.37 29.0951 39.33C29.0951 39.33 29.9651 35.98 29.6851 32.25C29.4051 28.51 27.9951 26.13 27.9951 26.13Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M32.9124 26.12C30.8524 28.59 30.2124 35.05 30.8224 36.91C30.8224 36.91 32.4624 34.49 33.0824 31.44C33.7024 28.39 32.9124 26.12 32.9124 26.12V26.12Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M61.3047 25.16C61.857 25.16 62.3047 24.7123 62.3047 24.16C62.3047 23.6077 61.857 23.16 61.3047 23.16C60.7524 23.16 60.3047 23.6077 60.3047 24.16C60.3047 24.7123 60.7524 25.16 61.3047 25.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M61.3047 23.16C61.857 23.16 62.3047 22.7123 62.3047 22.16C62.3047 21.6077 61.857 21.16 61.3047 21.16C60.7524 21.16 60.3047 21.6077 60.3047 22.16C60.3047 22.7123 60.7524 23.16 61.3047 23.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M63.3047 24.16C63.857 24.16 64.3047 23.7123 64.3047 23.16C64.3047 22.6077 63.857 22.16 63.3047 22.16C62.7524 22.16 62.3047 22.6077 62.3047 23.16C62.3047 23.7123 62.7524 24.16 63.3047 24.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M63.3047 20.16C63.857 20.16 64.3047 19.7123 64.3047 19.16C64.3047 18.6077 63.857 18.16 63.3047 18.16C62.7524 18.16 62.3047 18.6077 62.3047 19.16C62.3047 19.7123 62.7524 20.16 63.3047 20.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M83.3047 22.16C83.857 22.16 84.3047 21.7123 84.3047 21.16C84.3047 20.6077 83.857 20.16 83.3047 20.16C82.7524 20.16 82.3047 20.6077 82.3047 21.16C82.3047 21.7123 82.7524 22.16 83.3047 22.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M48.2656 15.48C50.6356 18.72 58.1556 22.53 60.5356 22.85C60.5356 22.85 58.0956 20.05 54.6456 17.94C51.1956 15.83 48.2656 15.49 48.2656 15.49V15.48Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M51.2327 23.45C53.2027 25.17 58.1827 24.97 59.5827 24.13C59.5827 24.13 57.6627 22.83 55.3027 22.6C52.9427 22.37 51.2227 23.45 51.2227 23.45H51.2327Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M62.9609 15.08C63.2409 17.68 67.0409 20.91 68.6409 21.25C68.6409 21.25 68.1109 18.99 66.5309 17.23C64.9509 15.47 62.9609 15.09 62.9609 15.09V15.08Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M59.1711 11.23C58.6211 13.73 60.6411 18.45 61.8011 19.51C61.8011 19.51 62.0611 17.2 61.3411 14.85C60.6211 12.5 59.1711 11.23 59.1711 11.23V11.23Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M71.764 9.17004C70.354 11.97 71.284 18.21 72.324 19.82C72.324 19.82 73.334 17.16 73.204 14.15C73.074 11.14 71.764 9.18004 71.764 9.18004V9.17004Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M50.6211 10.47C52.1611 14.18 58.5511 19.67 60.7911 20.54C60.7911 20.54 59.0911 17.24 56.2411 14.37C53.3911 11.5 50.6311 10.47 50.6311 10.47H50.6211Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M78.3047 21.16C78.857 21.16 79.3047 20.7123 79.3047 20.16C79.3047 19.6077 78.857 19.16 78.3047 19.16C77.7524 19.16 77.3047 19.6077 77.3047 20.16C77.3047 20.7123 77.7524 21.16 78.3047 21.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M81.3047 20.16C81.857 20.16 82.3047 19.7123 82.3047 19.16C82.3047 18.6077 81.857 18.16 81.3047 18.16C80.7524 18.16 80.3047 18.6077 80.3047 19.16C80.3047 19.7123 80.7524 20.16 81.3047 20.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M88.7733 6.31006C85.2433 8.24006 80.4933 15.1901 79.8633 17.5101C79.8633 17.5101 82.9633 15.4601 85.5033 12.3101C88.0433 9.16006 88.7733 6.31006 88.7733 6.31006V6.31006Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M81.8114 6.64001C79.3714 9.00001 77.3814 15.74 77.5314 17.77C77.5314 17.77 79.5714 15.41 80.8014 12.27C82.0314 9.13002 81.8014 6.64001 81.8014 6.64001H81.8114Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M91.3227 18.15C89.1027 17.03 84.2227 17.89 82.9727 18.77C82.9727 18.77 85.0827 19.56 87.4427 19.41C89.8027 19.26 91.3227 18.14 91.3227 18.14V18.15Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M91.0453 11.23C87.8053 11.66 82.8053 16.02 81.9453 17.82C81.9453 17.82 84.8453 17.14 87.4353 15.3C90.0253 13.46 91.0453 11.23 91.0453 11.23Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M79.3047 26.16C79.857 26.16 80.3047 25.7123 80.3047 25.16C80.3047 24.6077 79.857 24.16 79.3047 24.16C78.7524 24.16 78.3047 24.6077 78.3047 25.16C78.3047 25.7123 78.7524 26.16 79.3047 26.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M81.3047 27.16C81.857 27.16 82.3047 26.7123 82.3047 26.16C82.3047 25.6077 81.857 25.16 81.3047 25.16C80.7524 25.16 80.3047 25.6077 80.3047 26.16C80.3047 26.7123 80.7524 27.16 81.3047 27.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M94.96 19.8801C90.95 19.6701 83.25 23.1001 81.5 24.7401C81.5 24.7401 85.21 24.6201 89.03 23.2801C92.85 21.9401 94.96 19.8801 94.96 19.8801V19.8801Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M90.9244 31.3C89.6244 29.18 85.0144 27.35 83.4844 27.45C83.4844 27.45 84.8644 29.23 86.9544 30.34C89.0444 31.45 90.9244 31.3 90.9244 31.3Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M95.2216 25.2401C91.9916 23.9101 84.9116 25.0101 83.1016 26.1001C83.1016 26.1001 86.1616 27.0301 89.5816 26.8201C93.0016 26.6101 95.2116 25.2401 95.2116 25.2401H95.2216Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M72.3047 34.16C72.857 34.16 73.3047 33.7123 73.3047 33.16C73.3047 32.6077 72.857 32.16 72.3047 32.16C71.7524 32.16 71.3047 32.6077 71.3047 33.16C71.3047 33.7123 71.7524 34.16 72.3047 34.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M67.9018 48.29C69.2918 46.22 69.0318 41.27 68.3118 39.92C68.3118 39.92 67.2718 41.91 67.1218 44.28C66.9718 46.65 67.9018 48.29 67.9018 48.29Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M75.3941 48.63C76.3641 44.73 74.4641 36.52 73.1841 34.49C73.1841 34.49 72.5941 38.16 73.1841 42.16C73.7741 46.16 75.3941 48.63 75.3941 48.63Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M50.5 31.5099C53.84 29.2799 62.21 28.2799 64.55 28.7999C64.55 28.7999 61.3 30.5999 57.33 31.3999C53.36 32.1999 50.5 31.5199 50.5 31.5199V31.5099Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M46.6328 26.98C50.3228 25.39 58.7328 25.91 60.9428 26.84C60.9428 26.84 57.4228 28.02 53.3728 28.09C49.3228 28.16 46.6328 26.98 46.6328 26.98Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M50.7209 37.5901C53.3109 34.5101 61.0609 31.2201 63.4509 31.0601C63.4509 31.0601 60.8309 33.6901 57.2409 35.5701C53.6509 37.4501 50.7109 37.5901 50.7109 37.5901H50.7209Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M69.3047 35.16C69.857 35.16 70.3047 34.7123 70.3047 34.16C70.3047 33.6077 69.857 33.16 69.3047 33.16C68.7524 33.16 68.3047 33.6077 68.3047 34.16C68.3047 34.7123 68.7524 35.16 69.3047 35.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M66.3047 34.16C66.857 34.16 67.3047 33.7123 67.3047 33.16C67.3047 32.6077 66.857 32.16 66.3047 32.16C65.7524 32.16 65.3047 32.6077 65.3047 33.16C65.3047 33.7123 65.7524 34.16 66.3047 34.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M63.1555 49.24C66.1355 46.38 68.2055 38.19 67.8555 35.72C67.8555 35.72 65.3955 38.59 64.0355 42.4C62.6655 46.21 63.1555 49.25 63.1555 49.25V49.24Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M71.4515 45.5801C72.8615 42.8201 71.9316 36.6701 70.8916 35.0801C70.8916 35.0801 69.8816 37.7101 70.0116 40.6801C70.1416 43.6501 71.4515 45.5901 71.4515 45.5901V45.5801Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M57.4023 37.56C59.8723 37.9 64.2023 35.5 65.1023 34.27C65.1023 34.27 62.8523 34.2 60.6623 35.11C58.4723 36.02 57.4023 37.57 57.4023 37.57V37.56Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M57.582 46.95C61.012 44.86 65.442 37.69 65.952 35.35C65.952 35.35 62.952 37.54 60.562 40.81C58.162 44.07 57.582 46.96 57.582 46.96V46.95Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M79.3047 31.16C79.857 31.16 80.3047 30.7123 80.3047 30.16C80.3047 29.6077 79.857 29.16 79.3047 29.16C78.7524 29.16 78.3047 29.6077 78.3047 30.16C78.3047 30.7123 78.7524 31.16 79.3047 31.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M78.3047 33.16C78.857 33.16 79.3047 32.7123 79.3047 32.16C79.3047 31.6077 78.857 31.16 78.3047 31.16C77.7524 31.16 77.3047 31.6077 77.3047 32.16C77.3047 32.7123 77.7524 33.16 78.3047 33.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M77.3047 31.16C77.857 31.16 78.3047 30.7123 78.3047 30.16C78.3047 29.6077 77.857 29.16 77.3047 29.16C76.7524 29.16 76.3047 29.6077 76.3047 30.16C76.3047 30.7123 76.7524 31.16 77.3047 31.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M88.7744 39.3901C87.5944 36.3301 82.1844 32.4801 80.2344 32.0601C80.2344 32.0601 81.5844 34.7301 83.9744 36.8201C86.3644 38.9201 88.7744 39.3901 88.7744 39.3901V39.3901Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M92.5345 35.22C90.3645 32.56 83.7145 30.28 81.6445 30.4C81.6445 30.4 83.8545 32.63 86.9245 34.02C89.9945 35.41 92.5345 35.22 92.5345 35.22Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M76.5146 40.07C77.1946 38 75.7446 33.93 74.7946 32.98C74.7946 32.98 74.3846 34.9 74.8546 36.91C75.3146 38.92 76.5146 40.06 76.5146 40.06V40.07Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M80.1902 45.78C80.9802 42.62 78.7602 36.41 77.4102 34.97C77.4102 34.97 76.9802 37.9 77.7402 40.97C78.4902 44.03 80.1902 45.78 80.1902 45.78Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M64.5703 32.55C66.8703 31.81 70.0703 28.05 70.5203 26.66C70.5203 26.66 68.4903 27.54 66.8003 29.19C65.1003 30.84 64.5703 32.56 64.5703 32.56V32.55Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M70.4955 33.17C71.8255 31.65 71.6155 27.83 70.9455 26.76C70.9455 26.76 69.9455 28.24 69.7855 30.05C69.6255 31.86 70.4855 33.17 70.4855 33.17H70.4955Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M76.0934 32.73C76.2034 30.26 73.0134 26.91 71.5234 26.47C71.5234 26.47 71.6934 28.64 72.9634 30.43C74.2334 32.22 76.1034 32.73 76.1034 32.73H76.0934Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M80.1447 28.07C78.3847 26.35 73.5047 25.53 72.0547 25.9C72.0547 25.9 73.8047 27.28 76.0847 27.92C78.3647 28.56 80.1447 28.07 80.1447 28.07V28.07Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M81.3047 21.17C78.2847 20.87 73.0947 23.98 72.0547 25.52C72.0547 25.52 74.7947 25.49 77.4247 24.3C80.0547 23.11 81.3047 21.18 81.3047 21.18V21.17Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M76.7555 15.51C74.3255 17.1 72.0855 22.53 72.1255 24.3C72.1255 24.3 74.1755 22.63 75.5155 20.16C76.8555 17.69 76.7655 15.51 76.7655 15.51H76.7555Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M69.123 16.63C68.203 19.08 70.133 23.67 71.413 24.69C71.413 24.69 71.963 22.44 71.343 20.15C70.723 17.86 69.123 16.63 69.123 16.63V16.63Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M63.7344 20.59C64.6044 23.05 69.0344 25.33 70.6744 25.29C70.6744 25.29 69.6444 23.21 67.7044 21.85C65.7644 20.49 63.7344 20.58 63.7344 20.58V20.59Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M62.0547 26.77C64.2947 27.89 69.1647 26.92 70.3947 26C70.3947 26 68.2747 25.2199 65.9147 25.4099C63.5547 25.5999 62.0547 26.77 62.0547 26.77Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M85.9013 44.74C85.5213 41.16 81.1213 35.18 79.2812 34.02C79.2812 34.02 79.9312 37.26 81.7712 40.31C83.6112 43.36 85.8913 44.74 85.8913 44.74H85.9013Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M66.3047 15.16C66.857 15.16 67.3047 14.7123 67.3047 14.16C67.3047 13.6077 66.857 13.16 66.3047 13.16C65.7524 13.16 65.3047 13.6077 65.3047 14.16C65.3047 14.7123 65.7524 15.16 66.3047 15.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M68.3047 14.16C68.857 14.16 69.3047 13.7123 69.3047 13.16C69.3047 12.6077 68.857 12.16 68.3047 12.16C67.7524 12.16 67.3047 12.6077 67.3047 13.16C67.3047 13.7123 67.7524 14.16 68.3047 14.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M68.3047 16.16C68.857 16.16 69.3047 15.7123 69.3047 15.16C69.3047 14.6077 68.857 14.16 68.3047 14.16C67.7524 14.16 67.3047 14.6077 67.3047 15.16C67.3047 15.7123 67.7524 16.16 68.3047 16.16Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M66.7425 1.07007C65.1725 4.04007 65.7425 10.8701 66.6825 12.6801C66.6825 12.6801 67.8425 9.84007 67.8925 6.56007C67.9425 3.28007 66.7425 1.07007 66.7425 1.07007V1.07007Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M62.1645 4.54003C61.5345 7.07003 63.9745 11.42 65.3545 12.29C65.3545 12.29 65.6445 9.99003 64.7745 7.78003C63.9045 5.57003 62.1645 4.53003 62.1645 4.53003V4.54003Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M57.6133 6.30005C58.3133 9.13005 62.5633 13.34 64.1833 14.02C64.1833 14.02 63.2933 11.5 61.4633 9.30005C59.6333 7.10005 57.6133 6.30005 57.6133 6.30005V6.30005Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M72.2327 0.51001C69.8927 2.93001 68.5327 9.66001 68.9327 11.66C68.9327 11.66 70.8427 9.25001 71.8127 6.11001C72.7827 2.97001 72.2427 0.51001 72.2427 0.51001H72.2327Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M73.9951 2.13C72.7251 5.67 73.9751 13.37 75.0951 15.33C75.0951 15.33 75.9651 11.98 75.6851 8.25C75.4051 4.51 73.9951 2.13 73.9951 2.13V2.13Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M78.9124 2.12C76.8524 4.59 76.2124 11.05 76.8224 12.91C76.8224 12.91 78.4624 10.49 79.0824 7.44C79.7024 4.39 78.9124 2.12 78.9124 2.12Z" stroke="#7FA25A" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round"/>
    </g>
    <defs>
    <clipPath id="clip0_721_11611">
    <rect width="440" height="228" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default HausSeries3
