import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import Title from '../page-title'
import SubGrey from '../page-subtitle-grey'
import Image400 from '../page-image-400'
import { fontBold, fontLight, theme } from '../../util/style'
import { Link } from 'gatsby'

const HalfGridRowItem = ({ img = '/', heading, body, href, ...rest }) => {
  return (
    <Link to={href}>
      <Wrapper {...rest}>
        <Image400 src={img} ratio={340 / 256} />
        <TitleWrapper mb={['6px', '10px']} mt={[1, 2]}>
          {heading}
        </TitleWrapper>
        <DescriptionWrapper>{body}</DescriptionWrapper>
      </Wrapper>
    </Link>
  )
}
export default HalfGridRowItem

const Wrapper = styled(Box)`

`
const TitleWrapper = styled(Box)`
  font-size: 19px;
  ${fontBold()}
  line-height: 100%;
  @media (max-width: 800px) {
    font-size: 16px;
    margin-top: 1rem;
  }
  color: ${theme.colors.grey1};
`
const DescriptionWrapper = styled(Box)`
  font-size: 19px;
  ${fontLight()}
  line-height: 100%;
  color: ${theme.colors.grey7};
  @media (max-width: 800px) {
    font-size: 16px;
  }
`
