import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import Title from '../page-title'
import HalfGridRowItem from './half-grid-row-item'
import { theme } from '../../util/style'
import PageHeading from '../page-heading'

const HalfGridRow = ({ label, children, ...rest }) => {
  return (
    <Wrapper mx={2} pt={32} {...rest}>
      <Flex flexWrap={['wrap', 'nowrap']} justifyContent="space-between">
        <Box width={[1, 6 / 12]} mr={[0, 25]}>
          <Title
            style={{ color: '#9F9F9F', fontFamily: theme.font.fontFamilyLight }}
          >
            {label}
          </Title>
        </Box>
        <Box width={[1, 6 / 12]} mr={[0, 0]}>
          <ItemGrid>{children}</ItemGrid>
        </Box>
      </Flex>
    </Wrapper>
  )
}

export default HalfGridRow

const Wrapper = styled(Box)`
  border-top: 1px solid ${theme.colors.grey7};
`
const ItemGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
